import React, { Component } from 'react';

import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Attendance from "../../components/Attendance/Attendance";
import Comment from "../../components/Comment/Comment";

import GlobalProvider from "../../context/Provider";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    marginBottom: 15
}));

class Wishes extends Component {
    render() {
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={5}>
                    <Item style={{height: 400}}>
                        <Typography variant="h6" gutterBottom style={{textAlign: "center"}}>
                            Kehadiran
                        </Typography>
                        <Divider variant="middle" style={{margin: "0 0 20px 0"}}/>
                        <Attendance />
                    </Item>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Item>
                        <Typography variant="h6" gutterBottom style={{textAlign: "center"}}>
                            Komentar
                        </Typography>
                        <Divider variant="middle" style={{margin: "0 0 20px 0"}}/>
                        <Comment />
                    </Item>
                </Grid>
            </Grid>
        );
    }
}

export default GlobalProvider(Wishes);
