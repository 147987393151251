import React, { Component } from 'react';
import { Route, Routes } from "react-router-dom";

import './App.css';

import Wedding from "../../pages/Wedding/Wedding";
import Landing from "../../pages/Landing/Landing";

import GlobalProvider from "../../context/Provider";

class app extends Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<Landing/>}/>
        <Route path="/:name" element={<Landing/>}/>
        <Route path="/nikah" element={<Wedding/>}/>
      </Routes>
    );
  }
}

export default GlobalProvider(app);
