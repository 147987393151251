import React from 'react';

import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import Paper from '@mui/material/Paper';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

import {GlobalConsumer} from "../../context/Consumer";

const Navigation = (props) => {
    const [value, setValue] = React.useState(0);
    const ref = React.useRef(null);

    const scrollDown = (ref) => {
        window.scrollTo({
            top: ref.current.offsetTop,
            behavior: 'smooth',
        });
    };

    return (
        <Box sx={{pb: 7}} ref={ref}>
            <Paper style={{zIndex: 99}} sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                <BottomNavigation showLabels value={value}
                                  onChange={(event, newValue) => {
                                      setValue(newValue);
                                  }}>
                    <BottomNavigationAction label="Home" icon={<HomeIcon/>} onClick={() => scrollDown(props.home)}/>
                    <BottomNavigationAction label="Couple" icon={<PhotoLibraryIcon/>} onClick={() => scrollDown(props.couple)}/>
                    <BottomNavigationAction label="Event" icon={<EventIcon/>} onClick={() => scrollDown(props.event)}/>
                    <BottomNavigationAction label="Giving" icon={<CardGiftcardIcon/>} onClick={() => scrollDown(props.giving)}/>
                    <BottomNavigationAction label="Wishes" icon={<FavoriteIcon/>} onClick={() => scrollDown(props.wishes)}/>
                </BottomNavigation>
            </Paper>
        </Box>
    );
};


export default GlobalConsumer(Navigation);