import React from 'react';

import TextField from '@mui/material/TextField';

import "./Attendance.css";

import {GlobalConsumer} from "../../context/Consumer";
import Button from "@mui/material/Button";
import SendIcon from '@mui/icons-material/Send';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const Attendance = (props) => {
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return (
        <div style={{textAlign: "center"}}>
            <TextField
                id="outlined-multiline-static"
                label="Comment"
                multiline
                rows={5}
                fullWidth/>

            <FormControl fullWidth style={{margin:"8% 0"}}>
                <InputLabel id="demo-simple-select-label">Kehadiran</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Kehadiran"
                    onChange={handleChange}>
                    <MenuItem value={10}>Hadir</MenuItem>
                    <MenuItem value={20}>Tidak Hadir</MenuItem>
                </Select>
            </FormControl>
            <Button variant="contained" style={{margin: 10, width: 200}} endIcon={<SendIcon/>}>
                Send
            </Button>
        </div>
    );
};


export default GlobalConsumer(Attendance);