import React, {useState} from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import {GlobalConsumer} from "../../context/Consumer";

import "./PhotoLibrary.css";

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${
            size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
}

const itemData = [
    {
        img: `${process.env.PUBLIC_URL}/assets/photo-library/pl1.jpg`,
        title: 'photo-library',
        rows: 1,
        cols: 1,
    },
    {
        img: `${process.env.PUBLIC_URL}/assets/photo-library/pl2.jpg`,
        title: 'photo-library',
        rows: 1,
        cols: 1,
    },
    {
        img: `${process.env.PUBLIC_URL}/assets/photo-library/pl3.jpg`,
        title: 'photo-library',
        rows: 1,
        cols: 1,
    },
    {
        img: `${process.env.PUBLIC_URL}/assets/photo-library/pl4.jpg`,
        title: 'photo-library',
        rows: 1,
        cols: 1,
    },
    {
        img: `${process.env.PUBLIC_URL}/assets/photo-library/pl5.jpg`,
        title: 'photo-library',
        rows: 2,
        cols: 2,
    },
    {
        img: `${process.env.PUBLIC_URL}/assets/photo-library/pl6.jpg`,
        title: 'photo-library',
        rows: 1,
        cols: 1,
    },
    {
        img: `${process.env.PUBLIC_URL}/assets/photo-library/pl7.jpg`,
        title: 'photo-library',
        rows: 1,
        cols: 1,
    },
];

const PhotoLibrary = (props) => {
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState("false");

    const handleClose = (value) => {
        setOpen(false);
    };

    const handleImage = (value) => {
        setImage(value);
        setOpen(true);
    };

    return (
        <div>
            <ImageList variant="quilted">
                {itemData.map((item, idx) => (
                    <ImageListItem key={idx} cols={item.cols || 1} rows={item.rows || 1}>
                        <img
                            {...srcset(item.img, 121, item.rows, item.cols)}
                            alt={item.title}
                            onClick={(e) => handleImage(item)}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            <Modal
                className="image-modal"
                open={open}
                onClose={handleClose}
                closeAfterTransition>
                <Fade in={open} timeout={500}>
                    <img
                        src={image.img}
                        style={{maxHeight: "auto", maxWidth: "95%"}}
                        alt={image.title}/>
                </Fade>
            </Modal>
        </div>

    );
};


export default GlobalConsumer(PhotoLibrary);