import React, { Component } from "react";

import { RootContext, Data } from "./Context";

const Provider = RootContext.Provider;

const GlobalProvider = (Children) => {
  return class ParentComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        ...Data,
        updateProfile: this.updateProfile, // Tambahkan fungsi updateProfile
      };
    }

    // Fungsi untuk memperbarui profile
    updateProfile = (newProfile) => {
      this.setState({
        profile: {
          ...this.state.profile,
          ...newProfile,
        },
      });
    };

    render() {
      return (
        <Provider value={this.state}>
          <Children {...this.props} />
        </Provider>
      );
    }
  };
};

export default GlobalProvider;
