import React, { useEffect} from "react";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";

import {  useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

import {GlobalConsumer} from "../../context/Consumer";
import "./Landing.css";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://dyasta.com">
                Alhamsya Bintang Dyasta
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Landing = (props) => {
    const navigate = useNavigate(); // Hook untuk navigasi

    useEffect(() => {
        // Atau untuk menghapus semua data di LocalStorage
        localStorage.clear();
    }, []); // Array dependency kosong memastikan ini dijalankan hanya sekali

    const login = useGoogleLogin({
        onSuccess: async (codeResponse) => {
        //   console.log("codeResponse", codeResponse);
    
          // send codeResponse to the server
          const tokenResponse = await axios.get(
            `https://api.dyasta.com/auth/google/callback?code=${codeResponse.code}`
          );
        //   console.log("tokenResponse", tokenResponse);
          
          // Simpan data profile ke LocalStorage
          const profileData = tokenResponse.data.data;
          localStorage.setItem("profile", JSON.stringify(profileData));

          // Update profile di context
          props.updateProfile(profileData);

          // Redirect ke halaman wedding setelah login berhasil
          navigate("/nikah");
        },
        flow: "auth-code",
        // ux_mode: "redirect", // Pastikan ini diatur ke "redirect"
      });



    return (
            <Grid
                container
                component="main"
                sx={{height: '100vh'}}>
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: `url(${`${process.env.PUBLIC_URL}/assets/wedding/wedding.jpeg`})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}/>
                <Grid item
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      xs={12}
                      sm={8}
                      md={5}
                      component={Paper}
                      elevation={6} square>
                    <Box sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                
                            <Button
                            onClick={login}
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}>
                                Sign In
                            </Button>
                            <Copyright sx={{mt: 5}}/>
                    
                    </Box>
                </Grid>
            </Grid>
    );
};

export default GlobalConsumer(Landing);
