import React from 'react';

import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import "./Comment.css";

import {GlobalConsumer} from "../../context/Consumer";

const StyledPaper = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    color: theme.palette.text.primary,
}));

const message = `Truncation should be conditionally applicable on this long line of text
 as this is a much longer line than what the container can support. `;

const Comment = (props) => {
    return (
        <div>
            <StyledPaper sx={{my: 1, mx: 'auto', p: 2,}} elevation={3}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <Avatar
                            alt="Remy Sharp"
                            src="https://mui.com/static/images/avatar/1.jpg"
                            sx={{width: 50, height: 50}}/>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="subtitle2" gutterBottom>
                            Alhamsya
                        </Typography>
                        <Typography>{message}</Typography>
                    </Grid>
                </Grid>
            </StyledPaper>
            <StyledPaper sx={{my: 1, mx: 'auto', p: 2,}} elevation={3}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <Avatar
                            alt="Remy Sharp"
                            src="https://mui.com/static/images/avatar/1.jpg"
                            sx={{width: 50, height: 50}}/>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="subtitle2" gutterBottom>
                            Alhamsya
                        </Typography>
                        <Typography>{message}</Typography>
                    </Grid>
                </Grid>
            </StyledPaper>
            <StyledPaper sx={{my: 1, mx: 'auto', p: 2,}} elevation={3}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <Avatar
                            alt="Remy Sharp"
                            src="https://mui.com/static/images/avatar/1.jpg"
                            sx={{width: 50, height: 50}}/>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="subtitle2" gutterBottom>
                            Alhamsya
                        </Typography>
                        <Typography>{message}</Typography>
                    </Grid>
                </Grid>
            </StyledPaper>
            <StyledPaper sx={{my: 1, mx: 'auto', p: 2,}} elevation={3}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <Avatar
                            alt="Remy Sharp"
                            src="https://mui.com/static/images/avatar/1.jpg"
                            sx={{width: 50, height: 50}}/>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="subtitle2" gutterBottom>
                            Alhamsya
                        </Typography>
                        <Typography>{message}</Typography>
                    </Grid>
                </Grid>
            </StyledPaper>
            <StyledPaper sx={{my: 1, mx: 'auto', p: 2,}} elevation={3}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <Avatar
                            alt="Remy Sharp"
                            src="https://mui.com/static/images/avatar/1.jpg"
                            sx={{width: 50, height: 50}}/>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="subtitle2" gutterBottom>
                            Alhamsya
                        </Typography>
                        <Typography>{message}</Typography>
                    </Grid>
                </Grid>
            </StyledPaper>
            <StyledPaper sx={{my: 1, mx: 'auto', p: 2,}} elevation={3}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <Avatar
                            alt="Remy Sharp"
                            src="https://mui.com/static/images/avatar/1.jpg"
                            sx={{width: 50, height: 50}}/>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="subtitle2" gutterBottom>
                            Alhamsya
                        </Typography>
                        <Typography>{message}</Typography>
                    </Grid>
                </Grid>
            </StyledPaper>
            <StyledPaper sx={{my: 1, mx: 'auto', p: 2,}} elevation={3}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <Avatar
                            alt="Remy Sharp"
                            src="https://mui.com/static/images/avatar/1.jpg"
                            sx={{width: 50, height: 50}}/>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="subtitle2" gutterBottom>
                            Alhamsya
                        </Typography>
                        <Typography>{message}</Typography>
                    </Grid>
                </Grid>
            </StyledPaper>
            <StyledPaper sx={{my: 1, mx: 'auto', p: 2,}} elevation={3}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <Avatar
                            alt="Remy Sharp"
                            src="https://mui.com/static/images/avatar/1.jpg"
                            sx={{width: 50, height: 50}}/>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="subtitle2" gutterBottom>
                            Alhamsya
                        </Typography>
                        <Typography>{message}</Typography>
                    </Grid>
                </Grid>
            </StyledPaper>
            <StyledPaper sx={{my: 1, mx: 'auto', p: 2,}} elevation={3}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <Avatar
                            alt="Remy Sharp"
                            src="https://mui.com/static/images/avatar/1.jpg"
                            sx={{width: 50, height: 50}}/>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="subtitle2" gutterBottom>
                            Alhamsya
                        </Typography>
                        <Typography>{message}</Typography>
                    </Grid>
                </Grid>
            </StyledPaper>
        </div>
    );
};


export default GlobalConsumer(Comment);