import React from 'react';

import "./Payment.css";

import CardActions from '@mui/material/CardActions';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import {GlobalConsumer} from "../../context/Consumer";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import PaymentIcon from '@mui/icons-material/Payment';
import Avatar from '@mui/material/Avatar';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import CheckIcon from "@mui/icons-material/Check";
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}

const itemData = [
    {
        id: 1,
        img: `${process.env.PUBLIC_URL}/assets/payment/bca.png`,
        name: 'BCA',
        account_name: "Alhamsya Bintang Dyasta",
        account_number: "1400654821"
    },
    {
        id: 2,
        img: `${process.env.PUBLIC_URL}/assets/payment/gopay.jpeg`,
        name: 'GoPay',
        account_name: "Alhamsya Bintang Dyasta",
        account_number: "081221121997"
    },
    {
        id: 3,
        img: `${process.env.PUBLIC_URL}/assets/payment/gopay.jpeg`,
        name: 'GoPay',
        account_name: "Alhamsya Bintang Dyasta",
        account_number: "081221121997"
    },
    {
        id: 4,
        img: `${process.env.PUBLIC_URL}/assets/payment/gopay.jpeg`,
        name: 'GoPay',
        account_name: "Alhamsya Bintang Dyasta",
        account_number: "081221121997"
    },
    {
        id: 5,
        img: `${process.env.PUBLIC_URL}/assets/payment/gopay.jpeg`,
        name: 'GoPay',
        account_name: "Alhamsya Bintang Dyasta",
        account_number: "081221121997"
    },
    {
        id: 6,
        img: `${process.env.PUBLIC_URL}/assets/payment/gopay.jpeg`,
        name: 'GoPay',
        account_name: "Alhamsya Bintang Dyasta",
        account_number: "081221121997"
    },
    {
        id: 7,
        img: `${process.env.PUBLIC_URL}/assets/payment/gopay.jpeg`,
        name: 'GoPay',
        account_name: "Alhamsya Bintang Dyasta",
        account_number: "081221121997"
    },
];

const Payment = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const [stateToast, setStateToast] = React.useState({
        duration: 1300,
        open: false,
        Transition: Slide,
        value: ""
    });

    const handleClickToast = (Transition, value) => () => {
        setOpen(false);
        value = `Berhasil menyalin ${value.account_number} (${value.name})`
        setStateToast({
            ...stateToast,
            open: true,
            value: value,
        });
        navigator.clipboard.writeText(value)
    };

    const handleCloseToast = () => {
        setStateToast({
            ...stateToast,
            open: false,
        });
    };

    return (
        <div aria-hidden="true">
            <Card sx={{marginTop: 2, textAlign: "center"}} elevation={3}>
                <CardMedia
                    style={{height: 180}}
                    component="img"
                    height="140"
                    image={`${process.env.PUBLIC_URL}/assets/gift/envelope.jpeg`}
                    alt="green iguana"/>
                <CardContent style={{height: 150}}>
                    <Typography gutterBottom variant="h5" component="div">
                        Digital Envelope
                    </Typography>
                    <Divider variant="middle" style={{margin: "0 0 15px 0"}}/>
                    <Typography variant="body2" color="text.primary">
                        Sebagai ungkapan tanda kasih, apabila Bapak/Ibu/Sdr/i ingin memberikan kado, bisa
                        dikirimkan melalui transfer ke amplop digital
                    </Typography>
                </CardContent>
                <CardActions style={{height: 60, display: "block"}}>
                    <Button variant="contained" startIcon={<AccountBalanceWalletIcon/>}
                            onClick={handleClickOpen}>
                        Open Ampao Digital
                    </Button>
                </CardActions>
            </Card>
            <Dialog
                style={{top: "40%"}}
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}>
                <AppBar sx={{position: 'sticky'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close">
                            <PaymentIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            Salin Digital Envelope
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            Close
                        </Button>
                    </Toolbar>
                </AppBar>
                <List sx={{pt: 0}}>
                    {itemData.map((data, idx) => (
                        <div key={data.id.toString()}>
                            <ListItem onClick={handleClickToast(SlideTransition, data)} key={data.id.toString()}>
                                <ListItemAvatar>
                                    <Avatar
                                        alt="Remy Sharp"
                                        src={data.img}
                                        sx={{width: 50, height: 50}}/>
                                </ListItemAvatar>
                                <ListItemText style={{marginLeft: 20}} color="text.primary">
                                    <Typography variant="overline" display="block" gutterBottom>
                                        {data.name}
                                    </Typography>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {data.account_name}
                                    </Typography>
                                    <Typography variant="button" display="block" gutterBottom>
                                        {data.account_number}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <Divider/>
                        </div>
                    ))}
                </List>
            </Dialog>
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={stateToast.duration}
                open={stateToast.open}
                onClose={handleCloseToast}
                TransitionComponent={stateToast.Transition}
                key={stateToast.Transition.name}>
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" sx={{ width: 'inherit', textAlign: 'center' }}>
                    {stateToast.value}
                </Alert>
            </Snackbar>
        </div>

    );
};


export default GlobalConsumer(Payment);