import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';

import "./Event.css";

import {GlobalConsumer} from "../../context/Consumer";
import Divider from "@mui/material/Divider";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',
    height: 500,
}));

// const ImageRound = styled('img')(({ theme }) => ({
//     borderRadius:"50%",
//     objectFit: "cover",
//     width: "200px",
//     height: "200px",
// }));

const Event = (props) => {
    const showInMapClicked = () => {
        window.open("https://goo.gl/maps/KiHMrs4CbCnDo2SD7");
    };

    return (
        <Box sx={{ width: '100%', marginBottom: 3}}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={6}>
                    <Item>
                        <Typography variant="h5" gutterBottom>
                            Akad Nikah
                        </Typography>
                        <Divider variant="middle" style={{margin: "15px 0 0 0"}}/>
                        <div style={{marginTop: 50}}>
                            <InsertInvitationIcon sx={{ fontSize: 70 }} color="primary" />
                            <Typography variant="subtitle1" gutterBottom>
                                Sabtu, 29 Oktober 2022
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Pukul 08.00 WIB
                            </Typography>
                        </div>
                        <div style={{marginTop: 50}}>
                            <Typography variant="h6" gutterBottom>
                                Kediaman Mempelai Wanita
                            </Typography>
                            <MapIcon sx={{ fontSize: 70 }} color="primary" />
                            <Typography variant="subtitle2" gutterBottom>
                                Jl. Pare Lama Kauman RT. 02 RW. 14 Ds. Kandangan, Kec. Kandangan, Kab. Kediri
                            </Typography>
                            <Button variant="contained" style={{marginTop: 10}} disabled>
                                Buka Peta
                            </Button>
                        </div>
                    </Item>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Item>
                        <Typography variant="h5" gutterBottom>
                            Resepsi Pernikahan
                        </Typography>
                        <Divider variant="middle" style={{margin: "15px 0 0 0"}}/>
                        <div style={{marginTop: 50}}>
                            <CalendarMonthIcon sx={{ fontSize: 70 }} color="primary" />
                            <Typography variant="subtitle1" gutterBottom>
                                Minggu, 30 Oktober 2022
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Pukul 12.00 WIB - Selesai
                            </Typography>
                        </div>
                        <div style={{marginTop: 50}}>
                            <Typography variant="h6" gutterBottom>
                                Kediaman Mempelai Pria
                            </Typography>
                            <LocationOnIcon sx={{ fontSize: 70 }} color="primary" />
                            <Typography variant="subtitle2" gutterBottom>
                                Jl. Pare Lama Kauman RT. 02 RW. 14 Ds. Kandangan, Kec. Kandangan, Kab. Kediri
                            </Typography>
                            <Button
                                onClick={showInMapClicked}
                                variant="contained" style={{marginTop: 10}}>
                                Buka Peta
                            </Button>
                        </div>
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
};


export default GlobalConsumer(Event);