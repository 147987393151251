import React, {useRef, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Container from '@mui/material/Container';

import ScrollStatus from "../../components/ScrollStatus/ScrollStatus";
import Home from "../../components/Home/Home";
import PhotoLibrary from "../../components/PhotoLibrary/PhotoLibrary";
import Journey from "../../components/Journey/Journey";
import Event from "../../components/Event/Event";
import Navigation from "../../components/Navigation/Navigation";
import Giving from "../../containers/Giving/Giving";
import Wishes from "../../containers/Wishes/Wishes";

import {GlobalConsumer} from "../../context/Consumer";

import "./Wedding.css";

// import {useParams} from "react-router-dom";

const Wedding = (props) => {
    const homeSection = useRef(null);
    const coupleSection = useRef(null);
    const eventSection = useRef(null);
    const givingSection = useRef(null);
    const wishesSection = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        const profile = localStorage.getItem("profile");
        if (!profile) {
        // Redirect ke halaman login jika belum login
        navigate("/");
        }
    }, [navigate]);

    return (
        <React.Fragment>
            <Container maxWidth="md" className="main-content-layout">
                <ScrollStatus />
                <div ref={homeSection}>
                    <Home />
                </div>
                <div ref={coupleSection} style={{marginTop: 20}}>
                    <Journey/>
                    <PhotoLibrary/>
                </div>
                <div ref={eventSection}>
                    <Event/>
                </div>
                <div ref={givingSection}>
                    <Giving />
                </div>
                <div ref={wishesSection}>
                    <Wishes />
                </div>
                <Navigation
                    couple={coupleSection}
                    wishes={wishesSection}
                    home={homeSection}
                    giving={givingSection}
                    event={eventSection}/>
            </Container>
        </React.Fragment>
    );
};

export default GlobalConsumer(Wedding);
