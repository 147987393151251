import { createContext } from "react";

export const RootContext = createContext();

export const Data = {
  profile:{
    email: "",
    full_name: "",
    picture: ""
  }
};
