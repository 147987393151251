import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";

import Payment from "../../components/Payment/Payment";
import Gift from "../../components/Gift/Gift";

import GlobalProvider from "../../context/Provider";

class Giving extends Component {
    render() {
        return (
            <Box sx={{width: '100%', marginBottom: 3}}>
                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    <Grid item xs={12} md={6}>
                        <Gift/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Payment/>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

export default GlobalProvider(Giving);
